/* Todo: check if getOptions function is efficient (this function is triggered on $watch, angular.copy performance?)*/
// TODO: get this from socket.io request or http request.

angular.module('sq.jobs.forms.services.genome', [])
  .constant('FORM_OPTIONS_PROPERTY_CONVERSIONS', {
    phastCons: { type: 't', threshold: 'th' },
    phyloP: { type: 'k', threshold: 'kh' },
    cadd: { type: 'q', threshold: 'qh' },
  })
  .factory('genomeFactory', function (FORM_OPTIONS_PROPERTY_CONVERSIONS) {
    var genomeFactory = {};

    genomeFactory.selected = null;

    genomeFactory.genomes = [
      {
        id: 1,
        name: 'Human',
        value: 'Human',
        assemblies: [
          {
            id: 1,
            name: 'GRCh38/hg38',
            date: 2013,
            value: 'hg38',
          },
        ],
      },
      // {
      //   id: 2,
      //   name: 'Mouse',
      //   value: 'Mouse',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'GRCm38/mm10',
      //       date: 2011,
      //       value: 'mm10',
      //     },
      //     {
      //       id: 2,
      //       name: 'NCBI37/mm9',
      //       date: 2007,
      //       value: 'mm9',
      //     },
      //   ],
      // },
      // {
      //   id: 1,
      //   name: 'Rhesus',
      //   value: 'Rhesus',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'BCM Mmul_8.0.1/rheMac8',
      //       date: 2013,
      //       value: 'rheMac8',
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   name: 'Rat',
      //   value: 'Rat',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'RGSC 6.0/rn6',
      //       date: 2014,
      //       value: 'rn6',
      //     },
      //   ],
      // },
      // {
      //   id: 1,
      //   name: 'D. melanogaster',
      //   value: 'D. melanogaster',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'BDGP Release 6 + ISO1 MT/dm6',
      //       date: 2014,
      //       value: 'dm6',
      //     },
      //   ],
      // },
      // {
      //   id: 1,
      //   name: 'C.elegans',
      //   value: 'C.elegans',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'WBcel235/ce11',
      //       date: 2013,
      //       value: 'ce11',
      //     },
      //   ],
      // },
      // {
      //   id: 1,
      //   name: 'Yeast (S. cerevisiae)',
      //   value: 'Yeast (S. cerevisiae)',
      //   assemblies: [
      //     {
      //       id: 1,
      //       name: 'SacCer_Apr2011/sacCer3',
      //       date: 2011,
      //       value: 'sacCer3',
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   name: 'Mouse',
      //   value: 'Mouse',
      //   assemblies: [
      //     {
      //       id: 2,
      //       name: 'GRCm38/mm10',
      //       date: 2011,
      //       value: 'mm10',
      //     },
      //     {
      //       id: 1,
      //       name: 'NCBI Build 37/mm9',
      //       date: 2007,
      //       value: 'mm9',
      //     },
      //   ],
      // },
      // {
      //   id:3,
      //   name:'D. melanogaster',
      //   value:'D_melanogaster',
      //   assemblies:[
      //     {
      //       id:1,
      //       name:'Apr2006 (BDGP-R5/dm3)',
      //       value: 'dm3'
      //     }
      //   ]
      // },
      // {
      //   id:4,
      //   name:'C. elegans',
      //   value:'C_elegans',
      //   assemblies:[
      //     {
      //       id:1,
      //       name:'May2008 (WS190/ce6)',
      //       value: 'ce6'
      //     }
      //   ]
      // },
      // {
      //   id:5,
      //   name:'Zebra fish',
      //   value:'Zebra_fish',
      //   assemblies:[
      //     {
      //       id:1,
      //       name:'Dec2008 (DanRer6/re6)',
      //       value: 're6'
      //     }
      //   ]
      // }
    ];

    genomeFactory.phyloP = {};

    /*set form Option default values
    * phyloP and phastCons are enabled by default
    * cadd is disabled by default
    * to disabled any entry set value to null
    * only 'enabled' options are displayed
    */
    genomeFactory.phyloP.Human = [{ 'placental': 0.9 }, { 'primates': 0.9 },
    { 'vertebrate': 0.9 }];

    genomeFactory.phyloP.Mouse = [{ 'euarchontoglires': 0.9 }, { 'placental': 0.9 },
    { 'vertebrate': 0.9 }];

    genomeFactory.phyloP.D_melanogaster = [{ 'insects': 0.9 }];

    genomeFactory.phyloP.Zebra_fish = [{ 'vertebrate': 0.9 }];

    genomeFactory.phyloP.enabled = true;

    genomeFactory.phastCons = angular.copy(genomeFactory.phyloP);

    genomeFactory.cadd = angular.copy(genomeFactory.phyloP);

    genomeFactory.cadd.enabled = false;

    genomeFactory.defaultEnabledOptions = { 'phyloP': true, 'phastCons': true, 'cadd': false };

    genomeFactory.optionDisplayNames = { 'phyloP': 'PhyloP', 'phastCons': 'PhastCons', 'cadd': 'Cadd' };
    /*
    *@param selectedOrganismType : expects Human, Mouse, D_melanogaster, or Zebra_fish
    *@return options (Object)
    *@options : must include phastCons and phyloP properties
    * only 'enabled' options are displayed
    */
    genomeFactory.getOptions = function (selectedOrganismType) {
      var options = {};

      options['phyloP'] = angular.copy(genomeFactory.phyloP[selectedOrganismType]);

      options['phyloP']['enabled'] = genomeFactory.defaultEnabledOptions.phyloP;

      options['phyloP']['displayName'] = genomeFactory.optionDisplayNames.phyloP;

      options['phastCons'] = angular.copy(genomeFactory.phastCons[selectedOrganismType]);

      options['phastCons']['enabled'] = genomeFactory.defaultEnabledOptions.phastCons;

      options['phastCons']['displayName'] = genomeFactory.optionDisplayNames.phastCons;

      options['cadd'] = angular.copy(genomeFactory.cadd[selectedOrganismType]);

      options['cadd']['enabled'] = genomeFactory.defaultEnabledOptions.cadd;

      options['cadd']['displayName'] = genomeFactory.optionDisplayNames.cadd;

      return options;
    };


    /*
    *
    *formats the form options to reflect what's found in Query Annotation 10.pl
    *by joining keys to a ntype property, and joining corresponding values to a nthreshold property (both joined on comma)
    @param submittedOptions : key:value cadd (Obj), phastCons (Obj), phyloP (Obj) ; value corresponds to 'threshold' of that key, like keythreshold
    ****submittedOptions expected to be {'phastCons' : [ {type:threshold},...],'phyloP' : ..., 'cadd' : ...}
    *Properties formatted are:
    *@property phastCons (client) => t || phastconstype, th || phasconsthreshold
    *@property phyloP (client) => k || phyloptype, kh  || phylopthreshold
    *@property cadd (client) => q || caddtype, qh || caddthreshold
    **
    *Do we want to store options with 0 conservation threshold?
    */
    genomeFactory.formatOptions = function (submittedOptions) {
      var formattedOptions = {};

      for (var thisOption in submittedOptions) {
        if (!submittedOptions[thisOption].enabled) {
          continue;
        }
        var optionTypes = '';

        var optionThresholds = '';

        for (var i = 0; i < submittedOptions[thisOption].length; i++) {
          var oneOptionDetailObject = submittedOptions[thisOption][i];

          for (var subType in oneOptionDetailObject) //should be only one property
          {
            if (!parseFloat(oneOptionDetailObject[subType])) //should ignore 0 values
            {
              continue; //don't save if
            }

            optionTypes += subType + ','; //ex: if Human phlyoP or phastCons, subType could be vertebrate

            optionThresholds += oneOptionDetailObject[subType] + ',';
          }

        }

        optionTypes = optionTypes.substring(0, optionTypes.length - 1); //remove trailing comma
        optionThresholds = optionThresholds.substring(0, optionThresholds.length - 1);

        if (!optionThresholds) //if all values for an option are 0 or other falsy, don't include that option
        {
          continue;
        }

        formattedOptions[FORM_OPTIONS_PROPERTY_CONVERSIONS[thisOption]['type']] = optionTypes;

        formattedOptions[FORM_OPTIONS_PROPERTY_CONVERSIONS[thisOption]['threshold']] = optionThresholds;
      }

      return formattedOptions;
    };

    return genomeFactory;
  });
